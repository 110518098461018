<!--
 * @Author: your name
 * @Date: 2021-12-04 14:02:29
 * @LastEditTime: 2021-12-04 14:04:32
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jinyun_tech_bureau_demo-h5\src\views\Home\index.vue
-->
<template>
  <div class="home">
    <!-- <Breadcrumbnav></Breadcrumbnav> -->
    <!-- <LeftContainer class="container left" /> -->
    <CenterContainer />
    <!-- <RightContainer class="container right" /> -->
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    // Breadcrumbnav: () => import('./Breadcrumbnav.vue'),
    // LeftContainer: () => import('./LeftContainer.vue'),
    CenterContainer: () => import('./CenterContainer.vue'),
    // RightContainer: () => import('./RightContainer.vue')
  },
  data: () => {
    return {
      accessToken: undefined,
      user: {}
    };
  },
  //   created() {
  //   const that = this;
  //   // 向父页面发送本页面高度
  //   window.onload = function() {
  //     const scrollHeight = document.body.scrollHeight;
  //     window.parent.postMessage(scrollHeight, '*');
  //     // console.log('postmessage:scrollHeight ' + scrollHeight);
  //   };

  //   // 接受父页面发来的token,再用token获取鉴权信息以及用户信息获取
  //   window.addEventListener('message', async function(e) {
  //     let data = JSON.parse(JSON.stringify(e.data));

  //     that.accessToken = data.accessToken || undefined;
  //     console.log('accessToken', that.accessToken);


  //     if (!that.accessToken) {
  //       // 不是浙政钉，跳转 403
  //       that.$router.push('/403');
  //     } else {
  //       // const {host,port} = window.location;
  //       let user = await fetch(
  //         `https://portal.lishui.gov.cn:18080/api_2021/public/index.php/index/MicroPortalLogin/getUserInfoByAccessToken?accessToken=${that.accessToken}`
  //       );
  //       console.log('user: ', user);
  //       //todo:自行处理用户信息
  //     }
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.home {
  min-height: 100%;
  // height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  // flex-wrap: nowrap;
  // justify-content: space-between;
  // padding:10px;
  // height: 1660px;
  background: rgb(248, 248, 248);

  .container {
    flex-basis: 30%;
    // height: 1660px;
  }
  .center {
    flex-basis: 40%;
  }
}
</style>
