import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Element from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import 'normalize.css';
import './style.scss';

import * as echarts from 'echarts';
Vue.prototype.echarts = echarts;

import 'vue-easytable/libs/theme-default/index.css';
import VueEasytable from 'vue-easytable';
Vue.use(VueEasytable);
Vue.use(Element);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
